import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth, db } from '../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { ChevronDown } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { getEmployers } from '../../models/User'
import { Bar } from 'react-chartjs-2'
import ApplicationStages from './ApplicationStages'
import { getJobseekerApplications } from '../../models/JobApplication'
import {
  getEmployerProfile,
  getEmployerMembers,
  getJobSeekers,
  getAllEmployerProfiles // Add this import
} from '../../models/EmployerProfile'
import 'chart.js/auto' // This line is necessary to auto-register the required chart.js components

// Styled components
const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f3f4f6;
`

const Link = styled.a`
  color: white;
  background-color: ${softColors.icontext};
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.2s;
  border: 1px solid ${softColors.icontext};
  width: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: -10px;

  &:hover {
    background-color: white;
    color: ${softColors.icontext};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const ProgressBarContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${softColors.border};
  border-radius: 9999px;
  height: 0.5rem;
  margin-bottom: 0.5rem;
`

const ProgressBar = styled.div`
  height: 0.5rem;
  border-radius: 9999px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color || softColors.primary};
`

const MainContent = styled.main`
  flex: 1;
  overflow: auto;
  padding: 1.5rem;
`

const WelcomeHeader = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: ${(props) =>
      `repeat(${props.columns}, minmax(0, 1fr))`};
  }
`

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
`

const CardHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
`

const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`

const CardContent = styled.div`
  padding: 1rem 1.5rem;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #f9fafb;
`

const TableCell = styled.td`
  padding: 1rem 1.5rem;
  white-space: nowrap;
`

const OnboardingSection = styled(Card)`
  margin-bottom: 1.5rem;
  height: auto;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`

const SectionIcon = styled.span`
  margin-right: 0.5rem;
`

const SectionProgressBar = styled(ProgressBarContainer)`
  margin: 0.5rem 0;
  position: relative;
`

const SectionProgress = styled(ProgressBar)`
  background-color: ${(props) =>
    props.started ? softColors.success : softColors.danger};
`

const SectionContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  transition: max-height 0.3s ease-in-out;
`

const SectionDescription = styled.p`
  font-size: 0.875rem;
  color: ${softColors.textLight};
  line-height: 1.5;
`

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: -0.5rem;
  color: ${softColors.text};
`
const Tooltip = styled.span`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%; /* Arrow pointing down */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`

// Wrapper for the button and tooltip
const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`

// Button styled component
const Button = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.7;
  border: none;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b347e5;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #c859ff;
  }
`

const ViewApplicationsButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #c859ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;

  &:hover {
    background-color: white;
    color: #c859ff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const StatusCard = styled(Card)`
  padding: 1.5rem;
`

const StatusTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
`

const StageContainer = styled.div`
  margin-bottom: 1rem;
`

const StageLabel = styled.div`
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
`

const StageProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
`

const StageProgress = styled.div`
  height: 100%;
  border-radius: 4px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color};
`

const MatchesInfo = styled.div`
  color: #666;
  font-size: 0.875rem;
  margin-top: 1.5rem;
`

const ReviewButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 0.75rem;

  &:hover {
    background-color: #b347e5;
  }
`

export default function JobSeekerDash() {
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [applications, setApplications] = useState([])

  const [employers, setEmployers] = useState([])
  const [userName, setUserName] = useState('')
  const [openSections, setOpenSections] = useState({
    profile: false,
    postJob: false,
    survey: false
  })
  const navigate = useNavigate()

  const barData = {
    labels: [
      'Submitted',
      'Reviewed',
      'Shortlisted',
      'Interview',
      'Offer',
      'Rejected',
      'Hired'
    ],
    datasets: [
      {
        label: 'Application Status',
        data: [], // Sample data
        backgroundColor: '#C859FF',
        borderColor: '#C859FF',
        borderWidth: 1
      }
    ]
  }

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const user = auth.currentUser
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid))

          if (userDoc.exists()) {
            const userData = userDoc.data()
            setUserName(userData.first_name) // Changed to only use first_name

            const profileRef = doc(
              db,
              'users',
              user.uid,
              'profiles',
              'jobseeker'
            )
            const profileSnap = await getDoc(profileRef)

            if (profileSnap.exists()) {
              const profileData = profileSnap.data()
              setUserData(profileData)
            } else {
              setUserData({ intake_completed: false })
            }
          }
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [])
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const user = auth.currentUser
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid))

          if (userDoc.exists()) {
            const userData = userDoc.data()
            setUserData(userData)

            // Fetch jobseeker applications
            const applicationsData = await getJobseekerApplications(user.uid)
            setApplications(applicationsData)
          }
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [])

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const employerData = await getEmployers()
        setEmployers(employerData)
      } catch (error) {
        setError(error.message)
      }
    }

    fetchEmployers()
  }, [])

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const handleEmployerClick = (employerId) => {
    navigate(`/employer-profile-view/${employerId}`)
  }
  const ApplicationStatusSection = () => (
    <StatusCard>
      <StatusTitle>Applicant Stages</StatusTitle>

      <StageContainer>
        <StageLabel>Reviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="75%" color="#4ade80" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Interview</StageLabel>
        <StageProgressBar>
          <StageProgress width="45%" color="#60a5fa" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Rejected</StageLabel>
        <StageProgressBar>
          <StageProgress width="25%" color="#f87171" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Unreviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="55%" color="#fbbf24" />
        </StageProgressBar>
      </StageContainer>
    </StatusCard>
  )

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <Container>
      <JobSeekerNav intakeCompleted={userData?.intake_completed} />
      <MainContent>
        <WelcomeHeader> Welcome to Arena, {userName}!</WelcomeHeader>
        <Card style={{ marginBottom: '1.5rem' }}>
          <SectionTitle>Welcome to Arena! 🚀 </SectionTitle>
          <h4 style={{ fontWeight: '300' }}>
            Get ahead - by creating your profile now, you'll receive:
          </h4>
          <ul>
            <li>
              ⭐️ Priority access to roles from our growing network of employers
            </li>
            <li>
              💡 Job matches with positions that fit your unique skills, goals,
              and interests.
            </li>
            <li>
              🔍 Direct visibility to companies actively hiring on our platform
            </li>
            <li>
              💬 Direct messaging from recruiters for relevant opportunities{' '}
            </li>
            <li>
              ✅ Automatic application tracking so you always know where your
              applications stand
            </li>
          </ul>

          <p>
            <em>
              Note: While we're publicly launching our beta with select roles,
              we're rapidly adding new companies who are excited to connect with
              talent like you looking for career opportunities in sports, media
              & entertainment. We'll notify you the moment new relevant
              opportunities go live!{' '}
            </em>
          </p>
        </Card>

        {!userData?.intake_completed && (
          <OnboardingSection onClick={() => toggleSection('profile')}>
            <SectionHeader>
              <SectionTitle>
                <SectionIcon>🔥</SectionIcon>
                Complete your profile to get matched to relevant jobs and
                opportunities for you.
              </SectionTitle>
              <ChevronDown
                size={20}
                style={{
                  transform: openSections.profile ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s',
                  cursor: 'pointer'
                }}
              />
            </SectionHeader>
            <SectionProgressBar>
              <SectionProgress width="30%" started={true} />
            </SectionProgressBar>
            <SectionContent isOpen={openSections.profile}>
              <Link href="/jobseeker-intake">Edit Profile</Link>
            </SectionContent>
          </OnboardingSection>
        )}

        {/* Jobs Applied Section */}
        <Grid columns={2}>
          {/* Jobs Applied Section */}
          <Card>
            <CardHeader>
              <CardTitle>Jobs Applied</CardTitle>
            </CardHeader>
            <CardContent>
              <p
                style={{
                  fontSize: '2.25rem',
                  fontWeight: 'bold',
                  color: '#C859FF',
                  marginBottom: '0.5rem'
                }}
              >
                {userData?.jobs_applied?.length || 0}
              </p>
              <ViewApplicationsButton
                onClick={() => navigate('/application-tracking')}
              >
                View Applications
              </ViewApplicationsButton>
            </CardContent>
          </Card>
          {/* Application Status Section */}
          <Card>
            <ApplicationStages applications={applications} />
          </Card>
        </Grid>

        {/* Other Sections */}
        {/* MSBC Employers Section */}
        <Card>
          <CardHeader>
            <CardTitle>Employers</CardTitle>
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <TableHeader>Company</TableHeader>
                <TableHeader>Open Roles</TableHeader>
              </tr>
            </thead>
            <tbody>
              {employers.map((employer) => (
                <tr
                  key={employer.id}
                  onClick={() => handleEmployerClick(employer.id)} // Make the entire row clickable
                  style={{
                    cursor: 'pointer',
                    transition: 'background-color 0.2s'
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = '#f0f4f8')
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                >
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                      }}
                    >
                      {employer.logo ? (
                        <img
                          src={employer.logo}
                          alt={`${employer.name} logo`}
                          style={{
                            width: '2.5rem',
                            height: '2.5rem',
                            borderRadius: '9999px',
                            objectFit: 'cover'
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: '2.5rem',
                            height: '2.5rem',
                            borderRadius: '9999px',
                            backgroundColor: '#f3f4f6',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#6b7280'
                          }}
                        >
                          {employer.name.charAt(0)}
                        </div>
                      )}
                      <span style={{ fontWeight: '500' }}>{employer.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {employer.openRoles > 0 ? (
                      <span style={{ color: softColors.success }}>
                        {employer.openRoles} open roles
                      </span>
                    ) : (
                      'No open roles'
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>

        <Card style={{ marginTop: '2rem' }}>
          <CardTitle>How Our AI Works</CardTitle>
          <p
            style={{
              fontSize: '0.875rem',
              color: softColors.textLight,
              lineHeight: '1.5'
            }}
          >
            At Arena, we believe in transparency and equity throughout the
            hiring process. Our scoring system is designed to benefit both
            employers and job seekers. Here's a comprehensive look at how we
            evaluate candidates.{' '}
            <a
              href="https://arenatalent.notion.site/arena-methodology-overview"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: softColors.linkColor,
                textDecoration: 'underline'
              }}
            >
              Click here to view Arena's AI Methodology
            </a>
          </p>
        </Card>
      </MainContent>
    </Container>
  )
}
