// Weighted scoring system for job matches
const MATCH_WEIGHTS = {
  skills: {
    weight: 0.35,
    required: 0.6, // Required skills are weighted more heavily
    preferred: 0.4 // Preferred/nice-to-have skills
  },
  experience: {
    weight: 0.2,
    years: 0.5, // Years of experience match
    level: 0.5 // Career level match
  },
  education: {
    weight: 0.1
  },
  location: {
    weight: 0.1,
    remote: 1.0, // Perfect match for remote jobs
    hybrid: 0.7, // Partial match for hybrid
    onsite: 0.4 // Lower match for strict onsite if locations don't match
  },
  industry: {
    weight: 0.15,
    direct: 0.7, // Direct industry match
    related: 0.3 // Related industry experience
  },
  compensation: {
    weight: 0.1
  }
}

// Education level mapping for comparison
const EDUCATION_LEVELS = {
  'High School': 1,
  Associate: 2,
  Bachelor: 3,
  Master: 4,
  PhD: 5,
  Other: 1
}

// Career level mapping for comparison
const CAREER_LEVELS = {
  Internship: 1,
  'Entry Level': 2,
  'Mid Level': 3,
  'Senior Level': 4,
  Manager: 5,
  Director: 6,
  Executive: 7
}

const Match = (job, jobSeekerProfile, employerProfile) => {
  if (!jobSeekerProfile) return 0
  let totalScore = 0

  // 1. Skills Match
  const skillsScore = calculateSkillsMatch(job, jobSeekerProfile)
  totalScore += skillsScore * MATCH_WEIGHTS.skills.weight

  // 2. Experience Match
  const experienceScore = calculateExperienceMatch(job, jobSeekerProfile)
  totalScore += experienceScore * MATCH_WEIGHTS.experience.weight

  // 3. Education Match
  const educationScore = calculateEducationMatch(job, jobSeekerProfile)
  totalScore += educationScore * MATCH_WEIGHTS.education.weight

  // 4. Location Match
  const locationScore = calculateLocationMatch(job, jobSeekerProfile)
  totalScore += locationScore * MATCH_WEIGHTS.location.weight

  // 5. Industry Match
  const industryScore = calculateIndustryMatch(
    job,
    jobSeekerProfile,
    employerProfile
  )
  totalScore += industryScore * MATCH_WEIGHTS.industry.weight

  // 6. Compensation Match
  const compensationScore = calculateCompensationMatch(job, jobSeekerProfile)
  totalScore += compensationScore * MATCH_WEIGHTS.compensation.weight

  // Convert to percentage and round to nearest integer
  return Math.round(totalScore * 100)
}

const calculateSkillsMatch = (job, profile) => {
  if (!profile?.skills) return 0

  const requiredSkills = Array.isArray(job.requiredSkills)
    ? job.requiredSkills.map((s) => s.toLowerCase())
    : []
  const preferredSkills = Array.isArray(job.nice_to_haves)
    ? job.nice_to_haves.map((s) => s.toLowerCase())
    : []

  const profileSkills = new Set(profile.skills.map((s) => s.toLowerCase()))

  const requiredMatches = requiredSkills.filter((skill) =>
    profileSkills.has(skill)
  ).length
  const preferredMatches = preferredSkills.filter((skill) =>
    profileSkills.has(skill)
  ).length

  const requiredScore = requiredSkills.length
    ? requiredMatches / requiredSkills.length
    : 0
  const preferredScore = preferredSkills.length
    ? preferredMatches / preferredSkills.length
    : 0

  return (
    requiredScore * MATCH_WEIGHTS.skills.required +
    preferredScore * MATCH_WEIGHTS.skills.preferred
  )
}

const calculateExperienceMatch = (job, profile) => {
  const requiredYears = job.requiredExperience || 0
  const profileYears = profile.years_experience || 0
  const yearsScore = requiredYears > 0 ? profileYears / requiredYears : 0

  const jobLevel = CAREER_LEVELS[job.level] || 0
  const profileLevel = CAREER_LEVELS[profile.current_level] || 0
  const levelScore = jobLevel > 0 ? profileLevel / jobLevel : 0

  return (
    yearsScore * MATCH_WEIGHTS.experience.years +
    levelScore * MATCH_WEIGHTS.experience.level
  )
}

const calculateEducationMatch = (job, profile) => {
  const requiredEducation = EDUCATION_LEVELS[job.required_education] || 1
  const profileEducation = EDUCATION_LEVELS[profile.highest_education] || 1

  return profileEducation >= requiredEducation
    ? 1
    : profileEducation / requiredEducation
}

const calculateLocationMatch = (job, profile) => {
  if (job.work_policy === 'Remote') return MATCH_WEIGHTS.location.remote

  if (job.work_policy === 'Hybrid') {
    return profile.location === job.location
      ? MATCH_WEIGHTS.location.remote
      : MATCH_WEIGHTS.location.hybrid
  }

  // For onsite positions
  return profile.location === job.location
    ? MATCH_WEIGHTS.location.remote
    : MATCH_WEIGHTS.location.onsite
}

const calculateIndustryMatch = (job, profile, employerProfile) => {
  const jobIndustries = new Set(
    [employerProfile?.industry].flat().filter(Boolean)
  )

  // Check direct industry matches
  const directMatches =
    profile.industries?.filter((ind) => jobIndustries.has(ind)).length || 0

  // Consider related industry experience from work history
  const relatedIndustries = new Set(
    profile.work_history?.flatMap((job) => job.industry || []) || []
  )
  const relatedMatches = [...jobIndustries].filter((ind) =>
    relatedIndustries.has(ind)
  ).length

  return directMatches
    ? MATCH_WEIGHTS.industry.direct
    : relatedMatches
    ? MATCH_WEIGHTS.industry.related
    : 0
}

const calculateCompensationMatch = (job, profile) => {
  if (!job.salary?.range_start || !job.salary?.range_end) return 1

  // If the profile has a minimum salary requirement
  if (profile.minimum_salary) {
    const jobMaxSalary = job.salary.range_end
    if (jobMaxSalary < profile.minimum_salary) return 0

    // Partial match if the job's maximum is above minimum but starting is below
    if (job.salary.range_start < profile.minimum_salary) return 0.5
  }

  return 1
}

export default Match
