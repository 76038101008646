import React from 'react'
import styled from 'styled-components'
import {
  Search,
  Share2,
  Star,
  MoreVertical,
  Paperclip,
  Send,
  Home,
  Users,
  MessageSquare,
  Settings
} from 'lucide-react'
import EmployerNav from './Employers/EmployerNav'

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${softColors.background};
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const NavIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${softColors.textLight};
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${softColors.background};
    color: ${softColors.primary};
  }
`

const MainContent = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const Sidebar = styled.div`
  width: 320px;
  border-right: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
`

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${softColors.text};
`

const SearchContainer = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  width: 80%;
  padding: 8px 8px 8px 32px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};
  background-color: ${softColors.background};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SearchIcon = styled(Search)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${softColors.textLight};
`

const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  color: ${softColors.textLight};
  font-size: 14px;
`

const MainChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const RecipientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${softColors.border};
`

const RecipientName = styled.h2`
  font-weight: 600;
  color: ${softColors.text};
`

const RecipientStatus = styled.p`
  font-size: 14px;
  color: ${softColors.textLight};
`

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${softColors.textLight};
  padding: 4px;

  &:hover {
    color: ${softColors.primary};
  }
`

const ChatArea = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${softColors.textLight};
  background-color: ${softColors.background};
`

const InputArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const MessageInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SendButton = styled.button`
  background-color: ${softColors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
  }
`

export default function Messaging() {
  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Sidebar>
          <Header>
            <Title>Messages</Title>
            <SearchContainer>
              <SearchIcon size={16} />
              <SearchInput placeholder="Search messages" />
            </SearchContainer>
          </Header>
          <EmptyState>
            There are no job seekers to message at this time.
          </EmptyState>
        </Sidebar>
        <MainChat>
          <ChatHeader>
            <RecipientInfo>
              <Avatar />
              <div>
                <RecipientName>Start a conversation</RecipientName>
                <RecipientStatus>
                  Select a job seeker to message
                </RecipientStatus>
              </div>
            </RecipientInfo>
            <ActionButtons>
              <IconButton>
                <Share2 size={16} />
              </IconButton>
              <IconButton>
                <Star size={16} />
              </IconButton>
              <IconButton>
                <MoreVertical size={16} />
              </IconButton>
            </ActionButtons>
          </ChatHeader>
          <ChatArea>There are no job seekers to message at this time.</ChatArea>
          <InputArea>
            <IconButton>
              <Paperclip size={16} />
            </IconButton>
            <MessageInput placeholder="Type a message" disabled />
            <SendButton disabled>
              <Send size={16} />
            </SendButton>
          </InputArea>
        </MainChat>
      </MainContent>
    </Container>
  )
}
