import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Heart,
  Users,
  Building,
  Book,
  Link as GlobeIcon,
  Home,
  Briefcase,
  Linkedin,
  ArrowLeft
} from 'lucide-react'
import { getEmployerProfile } from '../../models/EmployerProfile'
import { getActiveEmployerJobPostings } from '../../models/JobPosting'
import JobSeekerNav from './JobSeekerNav'
import { auth } from '../../firebase'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: white;
  color: #caaae1;
  border: none;
  border: 1px solid #caaae1;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #b38fd1;
  }
`

const BackButton = styled(Button)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.8);
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-bottom: 40px;
`

const Card = styled.div`
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
  margin-top: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const CardTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f0f0f0;
  font-weight: bold;
`

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
  cursor: pointer;
  &:hover {
    background-color: #e8e8e8;
  }
`

const TableCell = styled.td`
  padding: 12px;
`

const EmptySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  gap: 16px;
`

const EmptyText = styled.p`
  color: #666;
  margin: 0;
  font-size: 16px;
`

const SocialMediaLink = styled.a`
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const CoverPhotoSection = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f0f4f8;
  margin-bottom: 0;
  border-radius: 8px;
`

const CoverPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

const Header = styled.div`
  position: relative;
  background-color: white;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: -80px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -100px;
`

const CompanyName = styled.h1`
  font-size: 32px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin: 0;
`

const CompanyWebsite = styled.a`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export default function JobSeekerEmployerProfileView() {
  const { employerId } = useParams()
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activeJobs, setActiveJobs] = useState([])
  const [isFavorited, setIsFavorited] = useState(false)
  const [favorites, setFavorites] = useState([])
  const [showFavoritedOnly, setShowFavoritedOnly] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const employerProfile = await getEmployerProfile(employerId)
        setProfile(employerProfile)

        const userId = auth.currentUser?.uid
        if (userId) {
          const jobseekerProfile = await getJobseekerProfile(userId)
          setIsFavorited(
            jobseekerProfile.favorites?.includes(employerId) || false
          )
        }
      } catch (error) {
        console.error('Error fetching employer profile:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchProfile()
  }, [employerId])

  useEffect(() => {
    const fetchActiveJobs = async () => {
      try {
        const jobs = await getActiveEmployerJobPostings(employerId)
        setActiveJobs(jobs)
      } catch (error) {
        console.error('Error fetching active jobs:', error)
      }
    }

    fetchActiveJobs()
  }, [employerId])

  const handleFavoriteToggle = async () => {
    const userId = auth.currentUser?.uid
    if (!userId) {
      alert('Please log in to favorite employers')
      return
    }

    try {
      const jobseekerProfile = await getJobseekerProfile(userId)
      let updatedFavorites = [...(jobseekerProfile.favorites || [])]

      if (isFavorited) {
        updatedFavorites = updatedFavorites.filter((id) => id !== employerId)
      } else {
        updatedFavorites.push(employerId)
      }

      await updateJobseekerProfile(userId, { favorites: updatedFavorites })
      setIsFavorited(!isFavorited)
    } catch (error) {
      console.error('Error updating favorites:', error)
      alert('Failed to update favorites. Please try again.')
    }
  }

  const handleJobClick = (jobId) => {
    navigate(`/job-opening/${jobId}`, {
      state: {
        companyName: profile.company_name,
        companyLogoUrl: profile.company_logo_url
      }
    })
  }

  const formatSalary = (salary) => {
    if (salary.type === 'unpaid') return 'Unpaid'
    if (salary.type === 'credit') return 'School Credit'

    const start = isNaN(salary.range_start)
      ? '-'
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }).format(salary.range_start)

    const end = isNaN(salary.range_end)
      ? '-'
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }).format(salary.range_end)

    if (start === '-' && end === '-') return '-'

    return `${start} - ${end} ${salary.type === 'hourly' ? '/hr' : '/year'}`
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  const formatUrl = (url) => {
    if (!url) return ''
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    }
    return `https://${url}`
  }

  if (loading) return <div>Loading...</div>
  if (!profile) return <div>No profile data available</div>

  return (
    <Container>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <MainContent>
        <CoverPhotoSection>
          <BackButton onClick={handleGoBack}>
            <ArrowLeft size={16} />
            Back
          </BackButton>
          <CoverPhoto src="/images/cover-photo.png.jpg" alt="Company Cover" />
        </CoverPhotoSection>
        <Header>
          <CompanyInfo>
            <Avatar
              src={
                profile.company_logo_url ||
                '/placeholder.svg?height=64&width=64'
              }
              alt={profile.company_name}
            />
            <div>
              <CompanyName>
                {profile.company_name || 'Company Name Not Set'}
              </CompanyName>
            </div>
          </CompanyInfo>
          <Button onClick={handleFavoriteToggle}>
            <Heart
              size={16}
              stroke="#CAAAE1"
              fill={isFavorited ? '#CAAAE1' : 'none'}
            />
            {isFavorited ? 'Favorited' : 'Favorite'}
          </Button>
        </Header>
        <CardGrid>
          <Card>
            <CardTitle>
              <Home size={20} /> Work Policy
            </CardTitle>
            {profile.work_policy || 'Work policy not set'}
          </Card>
          <Card>
            <CardTitle>
              <Users size={20} /> Employees
            </CardTitle>
            {profile.full_time_employees || 'Not specified'} Employees
          </Card>
          <Card>
            <CardTitle>
              <Building size={20} /> Industry & Sub-Industries
            </CardTitle>
            {profile.industry?.join(', ') || 'Not specified'}
            <br />
            {profile.sub_industry?.join(', ') || 'No sub-industries specified'}
          </Card>
        </CardGrid>

        <Card>
          <CardTitle>
            <Book size={20} /> About
          </CardTitle>
          {profile.company_description || 'Company description not set'}
        </Card>

        <Card>
          <CardTitle>
            <Briefcase size={20} /> Open Positions
          </CardTitle>
          {activeJobs.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <TableHeader>Position</TableHeader>
                  <TableHeader>Details</TableHeader>
                  <TableHeader>Salary</TableHeader>
                </tr>
              </thead>
              <tbody>
                {activeJobs.map((job) => (
                  <TableRow key={job.id} onClick={() => handleJobClick(job.id)}>
                    <TableCell>
                      <strong>{job.title}</strong>
                    </TableCell>
                    <TableCell>
                      {job.department}
                      <br />
                      {job.location} • {job.work_policy}
                    </TableCell>
                    <TableCell>{formatSalary(job.salary)}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ) : (
            <EmptySection>
              <EmptyText>No open positions yet</EmptyText>
            </EmptySection>
          )}
        </Card>

        <Card>
          <CardTitle>
            <GlobeIcon size={20} /> Social Media
          </CardTitle>
          {profile.company_linkedin || profile.company_website ? (
            <div style={{ display: 'flex', gap: '16px' }}>
              {profile.company_linkedin && (
                <SocialMediaLink
                  href={formatUrl(profile.company_linkedin)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin size={24} aria-label="LinkedIn" />
                </SocialMediaLink>
              )}
              {profile.company_website && (
                <SocialMediaLink
                  href={formatUrl(profile.company_website)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GlobeIcon size={24} aria-label="Website" />
                </SocialMediaLink>
              )}
            </div>
          ) : (
            <EmptySection>
              <EmptyText>No social media links added yet</EmptyText>
            </EmptySection>
          )}
        </Card>
      </MainContent>
    </Container>
  )
}
