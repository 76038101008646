import React, { useState, useEffect } from 'react'
import {
  Inbox,
  Search,
  Building,
  FileCheck,
  Settings,
  MessageSquare,
  LogOut,
  User,
  HelpCircle
} from 'lucide-react'
import { useNavigate, Link } from 'react-router-dom'
import { auth } from '../../firebase'
import { getJobseekerProfile } from '../../models/JobSeekerProfile'
import { onAuthStateChanged } from 'firebase/auth'
import styled from 'styled-components'
const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 16rem;
  background-color: #f3f4f6;
  border-right: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    width: 4rem;
  }
`

const LogoContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #ef4444;
  border-radius: 50%;
  position: absolute;
  //   right: ${({ isMobile }) => (isMobile ? '8px' : '16px')};
  left: 600%;
  top: 8px;
`

const IconContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 120px;
  height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const Nav = styled.nav`
  flex: 1;
`

const NavButtonWrapper = styled.div`
  position: relative;
  width: 80%;
  &:hover div {
    visibility: visible;
    opacity: 1;
  }
`

const NavButton = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #e5e7eb;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const IconWrapper = styled.span`
  margin-right: 0.75rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const ButtonText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

const BottomSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Tooltip = styled.div`
  position: absolute;
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  color: #dc2626;
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  width: 80%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #fee2e2;
    color: #b91c1c;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const HelpDropDown = styled.div`
  position: relative;
`

const HelpDropDownContent = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  width: 80%;
  padding: 0.5rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media (max-width: 768px) {
    width: 200px;
    left: 100%;
    top: 0;
  }
`

const DropDownLink = styled.a`
  padding: 10px 0px;
  display: block;
  color: #4a90e2;
  font-weight: 600;
  font-size: 0.87em;
  text-decoration: none;
  transition: color 0.2s;
`

const LinkText = styled.span`
  &:hover {
    color: #63b3ed;
    text-decoration: underline;
  }
`

function JobSeekerNav() {
  const [isHelpDropDownOpen, setIsHelpDropDownOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false)
  const [intakeCompleted, setIntakeCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const profile = await getJobseekerProfile(user.uid)
          setIntakeCompleted(profile?.intake_completed || false)
          setHasSeenWelcome(profile?.has_seen_welcome || false)
        } catch (error) {
          console.error('Error fetching profile:', error)
          setIntakeCompleted(false)
          setHasSeenWelcome(false)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
        setIntakeCompleted(false)
        setHasSeenWelcome(false)
        navigate('/login')
      }
    })

    return () => unsubscribe()
  }, [navigate])

  const handleHelpDropDown = () => {
    setIsHelpDropDownOpen((prev) => !prev)
  }

  const handleLogout = async () => {
    try {
      await auth.signOut()
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const navItems = [
    {
      to: '/jobseeker-dashboard',
      icon: <Inbox size={20} />,
      text: 'Dashboard',
      requiresProfile: false
    },
    {
      to: '/jobseeker-profile',
      icon: <User size={20} />,
      text: 'Profile',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to view your profile.'
    },
    {
      to: '/job-search',
      icon: <Search size={20} />,
      text: 'Search Jobs',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to search for jobs'
    },
    {
      to: '/company-search',
      icon: <Building size={20} />,
      text: 'Search Companies',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to browse companies'
    },
    {
      to: '/application-tracking',
      icon: <FileCheck size={20} />,
      text: 'Application Tracking',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to track applications'
    },
    {
      to: '/jobseeker-messaging',
      icon: (
        <IconContainer>
          <MessageSquare size={20} />
          {!hasSeenWelcome && <NotificationDot isMobile={isMobile} />}
        </IconContainer>
      ),
      text: 'Messaging',
      requiresProfile: true,
      tooltip: 'Complete your profile on the dashboard to message employers'
    }
  ]

  if (loading) {
    return (
      <NavContainer>
        <LogoContainer>
          <Logo src="/images/logo-black.png" alt="Company Logo" />
        </LogoContainer>
        <div className="flex items-center justify-center h-full">
          Loading...
        </div>
      </NavContainer>
    )
  }

  return (
    <NavContainer>
      <LogoContainer>
        <Logo src="/images/logo-black.png" alt="Company Logo" />
      </LogoContainer>
      <Nav>
        {navItems.map((item) => {
          const isDisabled = !intakeCompleted && item.requiresProfile
          return (
            <NavButtonWrapper key={item.to}>
              <NavButton to={item.to} disabled={isDisabled}>
                <IconWrapper>{item.icon}</IconWrapper>
                <ButtonText>{item.text}</ButtonText>
              </NavButton>
              {isDisabled && item.tooltip && <Tooltip>{item.tooltip}</Tooltip>}
            </NavButtonWrapper>
          )
        })}
      </Nav>
      <BottomSection>
        <HelpDropDown>
          <NavButton onClick={handleHelpDropDown}>
            <IconWrapper>
              <HelpCircle size={20} />
            </IconWrapper>
            <ButtonText>Need Help?</ButtonText>
          </NavButton>
          {isHelpDropDownOpen && (
            <HelpDropDownContent>
              <DropDownLink
                href="mailto:support@arenatalent.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkText>Email us</LinkText>
              </DropDownLink>
            </HelpDropDownContent>
          )}
        </HelpDropDown>
        <LogoutButton onClick={handleLogout}>
          <IconWrapper>
            <LogOut size={20} />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </LogoutButton>
      </BottomSection>
    </NavContainer>
  )
}

export default JobSeekerNav
