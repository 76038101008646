import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db, auth } from '../../firebase'
import Match from '../Match'
import {
  Search,
  Briefcase,
  MapPin,
  Building,
  Clock,
  X,
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield,
  ChevronDown,
  ChevronUp,
  Lock
} from 'lucide-react'
import { getEmployerProfile } from '../../models/EmployerProfile'
import { BanknoteIcon } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { useNavigate } from 'react-router-dom'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
`

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #4a5568;
  margin-right: 8px;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2d3748;
  }
`

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #caaae1;

  &:hover {
    background-color: #3182ce;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
`

const FilterTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`

const FilterSection = styled.div`
  margin-bottom: 16px;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
`

const FilterContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  max-height: 300px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
`

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 8px;

  &:focus {
    outline: none;
    border-color: #94a3b8;
  }
`

const Checkbox = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #caaae1;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #caaae1;
  }

  &:checked::after {
    content: '✓';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.5);
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
  cursor: pointer;
`

const ClearButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: #b68fd8;
  }
`

const JobList = styled.div`
  flex-grow: 1;
`

const JobListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const JobCard = styled.div`
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const JobCardContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;
`

const CompanyLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #f8f9fa;
`

const JobInfo = styled.div`
  flex: 1;
`

const JobTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
  margin: 0 0 8px 0;
`

const CompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4a5568;
  margin-bottom: 12px;
`

const JobMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 14px;
  color: #4a5568;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const SkeletonCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
`

const SkeletonContent = styled.div`
  display: flex;
  gap: 16px;
`

const SkeletonImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: #f1f5f9;
`

const SkeletonText = styled.div`
  flex: 1;

  div {
    height: ${(props) => props.height || '20px'};
    width: ${(props) => props.width || '100%'};
    background: #f1f5f9;
    border-radius: 4px;
    margin-bottom: 8px;
  }
`

const JobCardWrapper = styled.div`
  position: relative; // Added to establish positioning context

  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 16px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const MatchBubble = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #22c55e;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  z-index: 1; // Added to ensure bubble appears above other content

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  background-color: #1f2937;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  transition: all 0.2s ease;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 16px;
    border-width: 6px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.75rem;
  width: 90%;
  max-width: 400px;
  position: relative;
`

const ModalClose = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;

  &:hover {
    color: #2d3748;
  }
`
const ClearFilterButton = styled.button`
  color: #4299e1;
  font-size: 0.875rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

const ModalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #2d3748;
`

const SaveFilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin: 1rem 0;
  background-color: #caaae1;

  &:hover {
    background-color: #b794f4;
  }
`

const SavedFilterSection = styled(FilterSection)`
  margin-bottom: 1rem;
`

const SavedFilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  background-color: #f7fafc;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #edf2f7;
  }
`

const Disclaimer = styled.div`
  background-color: #f9fafb;
  border: 1px solid #e2e8f0;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const DeleteButton = styled.button`
  color: #e53e3e;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: #fed7d7;
  }
`

const LogoPlaceholder = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 8px;
  color: #a0aec0;
  font-size: 0.875rem;
`

const employmentTypes = [
  'Full-Time',
  'Part-time',
  'Internship',
  'Contract',
  'Hourly'
]

const levelOptions = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]

const CollapsibleFilterSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <FilterSection>
      <FilterHeader onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </FilterHeader>
      <FilterContent isOpen={isOpen}>{children}</FilterContent>
    </FilterSection>
  )
}

const SearchableCheckboxList = ({
  options,
  selectedOptions,
  onChange,
  name
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <>
      <Input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredOptions.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            name={name}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={onChange}
          />
          {option}
        </CheckboxLabel>
      ))}
    </>
  )
}

const JobSearch = () => {
  const [jobPostings, setJobPostings] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    searchTerm: '',
    companies: [],
    titles: [],
    recommendedSkills: [],
    locations: [],
    workPolicies: [],
    departments: [],
    levels: [],
    types: [],
    salaryMin: '',
    salaryMax: '',
    willingToSponsor: false,
    benefits: []
  })

  const [filteredJobs, setFilteredJobs] = useState([])
  const [jobSeekerProfile, setJobSeekerProfile] = useState(null)
  const getMatchColor = (matchPercentage) => {
    if (matchPercentage < 40) return '#e53e3e' // Red
    if (matchPercentage < 60) return '#ed8936' // Orange
    if (matchPercentage < 80) return '#ecc94b' // Yellow
    return '#22c55e' // Green
  }
  const [filterOptions, setFilterOptions] = useState({
    companies: [],
    titles: [],
    recommendedSkills: [],
    locations: [],
    departments: []
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [savedFilters, setSavedFilters] = useState([])
  const [modalError, setModalError] = useState(null)

  useEffect(() => {
    const userId = auth.currentUser?.uid
    if (userId) {
      fetchSavedFilters(userId)
    }
  }, [])

  // Add function to fetch saved filters
  const fetchSavedFilters = async (userId) => {
    try {
      const profile = await getJobseekerProfile(userId)
      setSavedFilters(profile?.saved_job_filters || [])
    } catch (error) {
      console.error('Error fetching saved filters:', error)
      setSavedFilters([])
    }
  }

  // Add function to save current filter
  const saveCurrentFilter = async () => {
    if (!filterName.trim()) {
      setModalError('Please enter a name for your filter')
      return
    }

    const userId = auth.currentUser?.uid
    if (!userId) return

    const newFilter = {
      id: Date.now().toString(),
      name: filterName.trim(),
      configuration: {
        ...filters
      }
    }

    try {
      const updatedFilters = [...savedFilters, newFilter]
      await updateJobseekerProfile(userId, {
        saved_job_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
      setIsModalOpen(false)
      setFilterName('')
      setModalError(null)
    } catch (error) {
      console.error('Error saving filter:', error)
      setModalError('Failed to save filter. Please try again.')
    }
  }

  // Add function to delete saved filter
  const deleteFilter = async (filterId, event) => {
    if (event) {
      event.stopPropagation()
    }
    const userId = auth.currentUser?.uid
    if (!userId) return

    try {
      const updatedFilters = savedFilters.filter(
        (filter) => filter.id !== filterId
      )
      await updateJobseekerProfile(userId, {
        saved_job_filters: updatedFilters
      })
      setSavedFilters(updatedFilters)
    } catch (error) {
      console.error('Error deleting filter:', error)
    }
  }

  // Add function to apply saved filter
  const applyFilter = (filter) => {
    setFilters(filter.configuration)
  }

  useEffect(() => {
    const fetchEmployersAndJobs = async () => {
      try {
        setLoading(true)

        const employersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'employer')
        )
        const employersSnapshot = await getDocs(employersQuery)

        const employerProfiles = {}

        for (const employerDoc of employersSnapshot.docs) {
          const userData = employerDoc.data()

          try {
            const profileData = await getEmployerProfile(employerDoc.id)

            if (profileData) {
              const employerId = profileData.employer_id || userData.employer_id
              if (employerId) {
                employerProfiles[employerId] = {
                  company_name:
                    profileData.company_name || userData.company_name,
                  company_logo_url:
                    profileData.company_logo_url || '/api/placeholder/50/50',
                  company_website: profileData.company_website,
                  industry: profileData.industry
                }
              }
            } else {
              if (userData.employer_id) {
                employerProfiles[userData.employer_id] = {
                  company_name: userData.company_name,
                  company_logo_url: '/api/placeholder/50/50'
                }
              }
            }
          } catch (error) {
            if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/api/placeholder/50/50'
              }
            }
          }
        }

        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('status', '==', 'active')
        )
        const snapshot = await getDocs(jobsQuery)

        const postings = snapshot.docs.map((doc) => {
          const jobData = doc.data()
          const employerId = jobData.employer_id?.toString()
          console.log('Processing job:', {
            title: jobData.title,
            employerId: employerId
          })

          const employerProfile = employerProfiles[employerId]

          return {
            id: doc.id,
            ...jobData,
            employerProfile: employerProfile || {
              company_name: jobData.company_name || 'Unknown Company',
              company_logo_url: '/api/placeholder/50/50'
            }
          }
        })

        setJobPostings(postings)
        setFilteredJobs(postings)

        // Extract unique filter options from job postings
        const options = postings.reduce(
          (acc, job) => {
            acc.companies.add(job.employerProfile.company_name)
            acc.titles.add(job.title)
            job.recommended_skills.forEach((skill) =>
              acc.recommendedSkills.add(skill)
            )
            acc.locations.add(job.location)
            acc.departments.add(job.department)
            return acc
          },
          {
            companies: new Set(),
            titles: new Set(),
            recommendedSkills: new Set(),
            locations: new Set(),
            departments: new Set()
          }
        )

        setFilterOptions({
          companies: Array.from(options.companies),
          titles: Array.from(options.titles),
          recommendedSkills: Array.from(options.recommendedSkills),
          locations: Array.from(options.locations),
          departments: Array.from(options.departments)
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployersAndJobs()
  }, [])

  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...jobPostings]

      if (filters.searchTerm) {
        const searchLower = filters.searchTerm.toLowerCase()
        filtered = filtered.filter(
          (job) =>
            job.title.toLowerCase().includes(searchLower) ||
            job.employerProfile?.company_name
              .toLowerCase()
              .includes(searchLower) ||
            job.description.toLowerCase().includes(searchLower)
        )
      }

      if (filters.companies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.companies.includes(job.employerProfile?.company_name)
        )
      }

      if (filters.titles.length > 0) {
        filtered = filtered.filter((job) => filters.titles.includes(job.title))
      }

      if (filters.recommendedSkills.length > 0) {
        filtered = filtered.filter((job) =>
          filters.recommendedSkills.some((skill) =>
            job.recommended_skills.includes(skill)
          )
        )
      }

      if (filters.locations.length > 0) {
        filtered = filtered.filter((job) =>
          filters.locations.includes(job.location)
        )
      }

      if (filters.workPolicies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.workPolicies.includes(job.work_policy)
        )
      }

      if (filters.departments.length > 0) {
        filtered = filtered.filter((job) =>
          filters.departments.includes(job.department)
        )
      }

      if (filters.levels.length > 0) {
        filtered = filtered.filter((job) => filters.levels.includes(job.level))
      }

      if (filters.types.length > 0) {
        filtered = filtered.filter((job) =>
          filters.types.some((type) => job.type.includes(type))
        )
      }

      if (filters.salaryMin) {
        filtered = filtered.filter(
          (job) => job.salary.range_start >= parseInt(filters.salaryMin)
        )
      }

      if (filters.salaryMax) {
        filtered = filtered.filter(
          (job) => job.salary.range_end <= parseInt(filters.salaryMax)
        )
      }

      if (filters.willingToSponsor) {
        filtered = filtered.filter((job) => job.willing_to_sponsor)
      }

      if (filters.benefits.length > 0) {
        filtered = filtered.filter((job) =>
          filters.benefits.every((benefit) => job.benefits.includes(benefit))
        )
      }

      setFilteredJobs(filtered)
    }

    applyFilters()
  }, [filters, jobPostings])

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      if (name === 'willingToSponsor') {
        setFilters((prev) => ({ ...prev, [name]: checked }))
      } else {
        setFilters((prev) => ({
          ...prev,
          [name]: checked
            ? [...prev[name], value]
            : prev[name].filter((item) => item !== value)
        }))
      }
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }))
    }
  }

  const clearFilters = () => {
    setFilters({
      searchTerm: '',
      companies: [],
      titles: [],
      recommendedSkills: [],
      locations: [],
      workPolicies: [],
      departments: [],
      levels: [],
      types: [],
      salaryMin: '',
      salaryMax: '',
      willingToSponsor: false,
      benefits: []
    })
  }

  const handleJobClick = (jobId, job, matchPercentage) => {
    navigate(`/job-opening/${jobId}`, {
      state: {
        companyName: job.employerProfile?.company_name,
        companyLogoUrl: job.employerProfile?.company_logo_url,
        matchScore: matchPercentage
      }
    })
  }

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = auth.currentUser?.uid
      if (userId) {
        const profile = await getJobseekerProfile(userId)
        setJobSeekerProfile(profile)
      }
    }

    fetchProfile()
  }, [])

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true)

        // Fetch all job postings
        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('status', '==', 'active')
        )
        const snapshot = await getDocs(jobsQuery)

        // Fetch employer profiles for all jobs
        const employerProfiles = {}
        for (const doc of snapshot.docs) {
          const job = doc.data()
          if (!employerProfiles[job.employer_id]) {
            employerProfiles[job.employer_id] = await getEmployerProfile(
              job.employer_id
            )
          }
        }

        // Map job postings to include match scores
        const postingsWithScores = snapshot.docs.map((doc) => {
          const job = { id: doc.id, ...doc.data() }
          const employerProfile = employerProfiles[job.employer_id]
          const matchScore = Match(job, jobSeekerProfile, employerProfile)
          return { ...job, matchScore }
        })

        setJobPostings(postingsWithScores)
      } catch (error) {
        console.error('Error fetching jobs:', error)
      } finally {
        setLoading(false)
      }
    }

    if (jobSeekerProfile) {
      fetchJobs()
    }
  }, [jobSeekerProfile])

  // Modify JobCard component to use getMatchColor for MatchBubble background
  const JobCard = ({ job, jobSeekerProfile, employerProfile }) => {
    const matchPercentage = Match(job, jobSeekerProfile, employerProfile)

    return (
      <JobCardWrapper
        onClick={() => handleJobClick(job.id, job, matchPercentage)}
      >
        <MatchBubble
          style={{ backgroundColor: getMatchColor(matchPercentage) }}
        >
          {matchPercentage}% Match
        </MatchBubble>
        <JobCardContent>
          {job.employerProfile?.company_logo_url &&
          job.employerProfile.company_logo_url !== '/api/placeholder/50/50' ? (
            <CompanyLogo
              src={job.employerProfile.company_logo_url}
              alt={`${job.employerProfile?.company_name || 'Company'} logo`}
              onError={(e) => {
                e.target.parentNode.replaceChild(
                  Object.assign(document.createElement('div'), {
                    className: e.target.className,
                    textContent: 'No Logo',
                    style:
                      'display: flex; align-items: center; justify-content: center;'
                  }),
                  e.target
                )
              }}
            />
          ) : (
            <LogoPlaceholder>No Logo</LogoPlaceholder>
          )}
          <JobInfo>
            <JobTitle>{job.title}</JobTitle>
            <CompanyName>
              <Building size={16} />
              <span>
                {job.employerProfile?.company_name || 'Unknown Company'}
              </span>
            </CompanyName>
            <JobMetadata>
              {job.location && (
                <div>
                  <MapPin size={16} />
                  <span>{job.location}</span>
                </div>
              )}
              {job.level && (
                <div>
                  <Briefcase size={16} />
                  <span>{job.level}</span>
                </div>
              )}
              {job.work_policy && (
                <div>
                  <Laptop size={16} />
                  <span>{job.work_policy}</span>
                </div>
              )}
              {job.type && (
                <div>
                  <Clock size={16} />
                  <span>
                    {Array.isArray(job.type) ? job.type.join(', ') : job.type}
                  </span>
                </div>
              )}
            </JobMetadata>
          </JobInfo>
        </JobCardContent>
      </JobCardWrapper>
    )
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>

      <MainContent>
        <Header>
          <Title>🔍 Search Jobs</Title>
        </Header>
        {/* Disclaimer message */}

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search by title, company, or keywords"
            value={filters.searchTerm}
            onChange={(e) => handleFilterChange(e)}
            name="searchTerm"
          />
          <SearchButton onClick={() => {}}>
            <Search size={18} />
            Search
          </SearchButton>
        </SearchBar>
        <Disclaimer>
          <span role="img" aria-label="light bulb">
            💡
          </span>
          Thank you for your patience as we launch Arena! Currently, there are a
          limited number of roles available on the platform as we actively
          partner with employers to expand our listings. We're working hard to
          bring new opportunities on board and will keep you updated as soon as
          more roles become available. We’re excited to help you find your next
          career move and appreciate your support as we get up and running!
        </Disclaimer>

        <ContentWrapper>
          <Sidebar>
            <FilterHeader>
              <h3>Filters</h3>
              {(filters.companies.length > 0 ||
                filters.titles.length > 0 ||
                filters.recommendedSkills.length > 0 ||
                filters.locations.length > 0 ||
                filters.workPolicies.length > 0 ||
                filters.departments.length > 0 ||
                filters.levels.length > 0 ||
                filters.types.length > 0 ||
                filters.salaryMin ||
                filters.salaryMax ||
                filters.willingToSponsor ||
                filters.benefits.length > 0) && (
                <ClearFilterButton onClick={clearFilters}>
                  Clear all
                </ClearFilterButton>
              )}
            </FilterHeader>
            <FilterCard>
              <CollapsibleFilterSection title="Companies">
                <SearchableCheckboxList
                  options={filterOptions.companies}
                  selectedOptions={filters.companies}
                  onChange={handleFilterChange}
                  name="companies"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Job Titles">
                <SearchableCheckboxList
                  options={filterOptions.titles}
                  selectedOptions={filters.titles}
                  onChange={handleFilterChange}
                  name="titles"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Skills">
                <SearchableCheckboxList
                  options={filterOptions.recommendedSkills}
                  selectedOptions={filters.recommendedSkills}
                  onChange={handleFilterChange}
                  name="recommendedSkills"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Locations">
                <SearchableCheckboxList
                  options={filterOptions.locations}
                  selectedOptions={filters.locations}
                  onChange={handleFilterChange}
                  name="locations"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Work Policy">
                <SearchableCheckboxList
                  options={workPolicyOptions}
                  selectedOptions={filters.workPolicies}
                  onChange={handleFilterChange}
                  name="workPolicies"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Department">
                <SearchableCheckboxList
                  options={filterOptions.departments}
                  selectedOptions={filters.departments}
                  onChange={handleFilterChange}
                  name="departments"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Level">
                <SearchableCheckboxList
                  options={levelOptions}
                  selectedOptions={filters.levels}
                  onChange={handleFilterChange}
                  name="levels"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Type">
                <SearchableCheckboxList
                  options={employmentTypes}
                  selectedOptions={filters.types}
                  onChange={handleFilterChange}
                  name="types"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Salary Range">
                <Input
                  type="number"
                  name="salaryMin"
                  placeholder="Min salary"
                  value={filters.salaryMin}
                  onChange={handleFilterChange}
                />
                <Input
                  type="number"
                  name="salaryMax"
                  placeholder="Max salary"
                  value={filters.salaryMax}
                  onChange={handleFilterChange}
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Benefits">
                <SearchableCheckboxList
                  options={benefitCategories.map((b) => b.label)}
                  selectedOptions={filters.benefits}
                  onChange={handleFilterChange}
                  name="benefits"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Other">
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="willingToSponsor"
                    checked={filters.willingToSponsor}
                    onChange={handleFilterChange}
                  />
                  Willing to Sponsor
                </CheckboxLabel>
              </CollapsibleFilterSection>
              <SaveFilterButton onClick={() => setIsModalOpen(true)}>
                <Heart size={18} />
                Save Filter
              </SaveFilterButton>

              {/* Add Saved Filters section */}
              {savedFilters.length > 0 && (
                <CollapsibleFilterSection
                  title={`Saved Filters (${savedFilters.length})`}
                >
                  {savedFilters.map((filter) => (
                    <SavedFilterItem key={filter.id}>
                      <span
                        onClick={() => applyFilter(filter)}
                        style={{
                          flex: 1,
                          fontSize: '0.875rem',
                          color: '#4A5568'
                        }}
                      >
                        {filter.name}
                      </span>
                      <DeleteButton
                        onClick={(e) => deleteFilter(filter.id, e)}
                        title="Delete filter"
                      >
                        <X size={16} />
                      </DeleteButton>
                    </SavedFilterItem>
                  ))}
                </CollapsibleFilterSection>
              )}
            </FilterCard>
          </Sidebar>

          <JobList>
            <JobListHeader>
              <h2>Available Jobs ({filteredJobs.length})</h2>
            </JobListHeader>
            {loading ? (
              <>
                {[...Array(3)].map((_, i) => (
                  <SkeletonCard key={i}>
                    <SkeletonContent>
                      <SkeletonImage />
                      <SkeletonText>
                        <div style={{ width: '33%', height: '24px' }} />
                        <div style={{ width: '25%' }} />
                        <div style={{ width: '40%' }} />
                      </SkeletonText>
                    </SkeletonContent>
                  </SkeletonCard>
                ))}
              </>
            ) : (
              <>
                {filteredJobs.map((job) => (
                  <JobCard
                    key={job.id}
                    job={job}
                    jobSeekerProfile={jobSeekerProfile}
                    employerProfile={job.employerProfile}
                  />
                ))}
              </>
            )}
          </JobList>
        </ContentWrapper>
        {isModalOpen && (
          <ModalOverlay>
            <ModalContent>
              <ModalClose
                onClick={() => {
                  setIsModalOpen(false)
                  setFilterName('')
                  setModalError(null)
                }}
              >
                <X size={20} />
              </ModalClose>
              <ModalTitle>Save Current Filter</ModalTitle>
              <Input
                type="text"
                placeholder="Enter a name for your filter"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
              {modalError && (
                <div
                  style={{
                    color: '#e53e3e',
                    marginTop: '0.5rem',
                    fontSize: '0.875rem'
                  }}
                >
                  {modalError}
                </div>
              )}
              <SaveFilterButton onClick={saveCurrentFilter}>
                Save Filter
              </SaveFilterButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </MainContent>
    </PageWrapper>
  )
}

export default JobSearch
