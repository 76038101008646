import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Search,
  Save,
  ChevronDown,
  ChevronUp,
  ArrowUpDown,
  X,
  User,
  Heart,
  Plus
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import EmployerNav from './EmployerNav'
import { getAllJobSeekerProfiles } from '../../models/JobSeekerProfile'
import { auth } from '../../firebase'
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import { db } from '../../firebase'
import { getJobSeekers } from '../../models/User'

// Constants
const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Hebrew',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish'
].sort()

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const athleteStatusOptions = [
  'Current Collegiate Athlete',
  'Former Collegiate Athlete',
  'Current Professional Athlete',
  'Former Professional Athlete'
]

const raceOptions = [
  'Asian',
  'Black',
  'Asian',
  'Hispanic',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White'
]

const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female'
]

const veteranStatusOptions = ['Veteran', 'Active Duty', 'Reservist']

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

// Styled Components

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  &:hover {
    background-color: #0060df;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
`

const NestedFilterContent = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
`

const Checkbox = styled.input`
  margin-right: 0.5rem;
`

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const JobSeekerList = styled.div`
  display: grid;
  gap: 1rem;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const SortButton = styled(Button)`
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: -40px;

  &:hover {
    background-color: #f0f0f0;
  }
`

const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  margin-left: 0.5rem;
  color: #666;

  &:hover {
    color: #333;
  }
`

const MatchPercentage = styled.div`
  color: ${(props) => props.color};
  font-size: 0.9rem;
  font-weight: bold;
  position: relative;
  cursor: help;

  &:hover > div {
    display: block;
  }
`

const TooltipContent = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
`
const StrengthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const CategoryHeader = styled.div`
  font-weight: 600;
  color: #333;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  grid-column: 1 / -1;
  border-bottom: 1px solid #eee;
`

const FavoriteFilterWrapper = styled.div`
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #e9ecef;
`

const FavoriteLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  color: #333;

  &:hover {
    color: #000;
  }
`
const FavoriteCount = styled.span`
  background-color: #caaae1;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  margin-left: auto;
`
const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`

const PageIndicator = styled.div`
  font-size: 1rem;
`

///////
const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const FilterSection = styled.div`
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
`

const FilterContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  margin-left: 0.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const CheckboxLabel = styled(RadioLabel)`
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const FilterCount = styled.span`
  color: #718096;
  font-size: 0.75rem;
  margin-left: auto;
  background-color: #f7fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const ClearButton = styled.button`
  color: #caaae1;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.375rem;

  &:hover {
    background-color: #f7f7f7;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const FilterSearchInput = styled(SearchInput)`
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`

const FilterCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`

const StrengthCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`
const JobSeekerCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: white;
  margin-bottom: 1rem;

  &:hover {
    background-color: #f8fafc;
  }
`

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`

const ProfileImageFallback = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: #64748b;
`

const JobSeekerInfo = styled.div`
  flex-grow: 1;
`

const JobSeekerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

const JobSeekerName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1a202c;
  margin: 0;
`

const Pronouns = styled.span`
  font-size: 0.875rem;
  color: #64748b;
`

const JobSeekerDetails = styled.p`
  font-size: 0.875rem;
  color: #4a5568;
  margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
`

const Tag = styled.span`
  background-color: #e9d8f8;
  color: #805ad5;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
`
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 3rem;
`

const FiltersColumn = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
`

const JobSeekersColumn = styled.div`
  min-width: 0; // Prevents overflow
`

const JobSeekersHeader = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

export default function CandidateSourcing() {
  const navigate = useNavigate()
  const [jobSeekers, setJobSeekers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true) // Add this state

  const [filteredJobSeekers, setFilteredJobSeekers] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [locationSearch, setLocationSearch] = useState('')
  const [selectedCurrentEmployers, setSelectedCurrentEmployers] = useState([])
  const [currentEmployerSearch, setCurrentEmployerSearch] = useState('')
  const [selectedCurrentTitles, setSelectedCurrentTitles] = useState([])
  const [currentTitleSearch, setCurrentTitleSearch] = useState('')
  const [selectedWorkExperience, setSelectedWorkExperience] = useState([])
  const [selectedCurrentLevel, setSelectedCurrentLevel] = useState([])
  const [selectedHighestDegree, setSelectedHighestDegree] = useState([])
  const [selectedMajors, setSelectedMajors] = useState([])
  const [currentUserEmployerId, setCurrentUserEmployerId] = useState(null)

  const [majorSearch, setMajorSearch] = useState('')
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [universitySearch, setUniversitySearch] = useState('')
  const [selectedHBCU, setSelectedHBCU] = useState(false)
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [strengthSearch, setStrengthSearch] = useState('')
  const [selectedWillingToRelocate, setSelectedWillingToRelocate] =
    useState(null)
  const [selectedGenders, setSelectedGenders] = useState([])
  const [selectedRaces, setSelectedRaces] = useState([])
  const [selectedStartupExperience, setSelectedStartupExperience] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedAthleteStatus, setSelectedAthleteStatus] = useState([])
  const [selectedVeteranStatus, setSelectedVeteranStatus] = useState([])
  const [openFilters, setOpenFilters] = useState({})
  const [sortOrder, setSortOrder] = useState('desc')
  const [showMatchPercentage, setShowMatchPercentage] = useState(true)
  const [openDemographicFilters, setOpenDemographicFilters] = useState({})
  const [favorites, setFavorites] = useState(new Set())
  const [pipeline, setPipeline] = useState(new Set())
  const [error, setError] = useState(null)

  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSubcategories, setSelectedSubcategories] = useState([])
  const [industrySearch, setIndustrySearch] = useState('')
  const [subcategorySearch, setSubcategorySearch] = useState('')
  const [showFavoritedOnly, setShowFavoritedOnly] = useState(false)
  const [companyEmployerIds, setCompanyEmployerIds] = useState([])
  const [favoritedCount, setFavoritedCount] = useState(0)
  const [currentUserCompanyId, setCurrentUserCompanyId] = useState(null)
  const JOB_SEEKERS_PER_PAGE = 10 // Number of job seekers per page
  const [currentPage, setCurrentPage] = useState(1) // Tracks current page
  const [lastVisible, setLastVisible] = useState(null) // Store the last document
  const [hasMore, setHasMore] = useState(true) // Check if there are more job seekers to load

  // Calculate indices for paginated data
  const startIndex = (currentPage - 1) * JOB_SEEKERS_PER_PAGE
  const endIndex = startIndex + JOB_SEEKERS_PER_PAGE
  const paginatedJobSeekers = filteredJobSeekers.slice(startIndex, endIndex)

  useEffect(() => {
    const fetchJobSeekersAndCompanyInfo = async () => {
      try {
        setLoading(true)
        setError(null)

        // Fetch the current user's company ID and employer IDs
        const user = auth.currentUser
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          if (userDoc.exists()) {
            const userData = userDoc.data()
            setCurrentUserCompanyId(userData.company_id)

            // Fetch all employer IDs for the company
            const employersQuery = query(
              collection(db, 'users'),
              where('company_id', '==', userData.company_id)
            )
            const employersSnapshot = await getDocs(employersQuery)
            const employerIds = employersSnapshot.docs.map((doc) => doc.id)
            setCompanyEmployerIds(employerIds)
          } else {
            throw new Error("User document doesn't exist")
          }
        } else {
          throw new Error('No authenticated user')
        }

        // Fetch job seekers
        const jobSeekersData = await getJobSeekers()
        const profiles = await getAllJobSeekerProfiles(
          jobSeekersData.map((user) => user.id)
        )

        const combinedData = jobSeekersData.map((user) => {
          const profile = profiles[user.id] || {}
          return {
            id: user.id,
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            email: user.email || '',
            ...profile,
            name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
            has_profile: !!profiles[user.id],
            created_at: user.created_at,
            favorites: profile.favorites || []
          }
        })

        const sortedData = combinedData.sort((a, b) => {
          if (a.has_profile !== b.has_profile) {
            return b.has_profile ? 1 : -1
          }
          return b.created_at - a.created_at
        })

        setJobSeekers(sortedData)
        setFilteredJobSeekers(sortedData)
      } catch (err) {
        console.error('Error fetching job seekers:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }
    fetchJobSeekersAndCompanyInfo()
  }, [])

  useEffect(() => {
    filterJobSeekers()
  }, [searchTerm, showFavoritedOnly, companyEmployerIds, jobSeekers])

  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        setLoading(true)

        // First, get all users with role 'jobseeker'
        const jobSeekersData = await getJobSeekers(60)

        // Then, get their profiles (if they exist)
        const profiles = await getAllJobSeekerProfiles(
          jobSeekersData.map((user) => user.id)
        )

        // Combine user data with profile data
        const combinedData = jobSeekersData.map((user) => {
          const profile = profiles[user.id] || {}

          return {
            id: user.id,
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
            email: user.email || '',
            // Profile data (using nullish coalescing to handle missing data)
            pronouns: profile.pronouns ?? null,
            location:
              profile.city && profile.state
                ? `${profile.city}, ${profile.state}`
                : null,
            current_employer: profile.current_employer ?? null,
            current_title: profile.current_title ?? null,
            work_experience: profile.years_experience ?? null,
            willing_to_relocate:
              profile.open_to_work?.includes('willing_to_relocate') ?? false,
            startup_experience: profile.startup_experience ?? false,
            languages: profile.languages ?? [],
            strengths: profile.top_strengths ?? [],
            athlete_status: profile.athlete_status ?? null,
            favorited_companies: profile.favorited_companies ?? [],
            education:
              profile.education?.map((edu) => ({
                university: edu.institution || edu.university,
                degree: edu.degree_type || edu.degree,
                major: edu.field_of_study || edu.major,
                is_hbcu: edu.is_hbcu || false
              })) ?? [],
            // Add a profile completion indicator
            has_profile: !!profiles[user.id],
            created_at: user.created_at,
            // Add a default rating for sorting
            rating: Math.random() * 100 // This is a placeholder - implement your actual rating logic
          }
        })

        // Sort by profile completion and then by creation date
        const sortedData = combinedData.sort((a, b) => {
          if (a.has_profile !== b.has_profile) {
            return b.has_profile ? 1 : -1 // Show complete profiles first
          }
          // Then sort by creation date
          return b.created_at - a.created_at
        })

        setJobSeekers(sortedData)
        setFilteredJobSeekers(sortedData)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching job seekers:', err)
        setError(err.message)
        setLoading(false)
      }
    }

    fetchJobSeekers()
  }, []) // Empty dependency array means this runs once on component mount

  // // Modify the existing filter function to handle incomplete profiles
  // const filterJobSeekers = () => {
  //   if (!jobSeekers.length) return

  //   const filtered = jobSeekers.filter((jobSeeker) => {
  //     // Basic text search across all fields
  //     if (searchTerm) {
  //       const searchableFields = [
  //         jobSeeker.name,
  //         jobSeeker.email,
  //         jobSeeker.location,
  //         jobSeeker.current_employer,
  //         jobSeeker.current_title,
  //         jobSeeker.education
  //           ?.map((edu) => `${edu.university} ${edu.degree} ${edu.major}`)
  //           .join(' '),
  //         jobSeeker.strengths?.join(' '),
  //         jobSeeker.languages?.join(' ')
  //       ]
  //         .filter(Boolean)
  //         .join(' ')
  //         .toLowerCase()

  //       if (!searchableFields.includes(searchTerm.toLowerCase())) {
  //         return false
  //       }
  //     }

  //     // Handle favorites filter
  //     if (
  //       showFavoritedOnly &&
  //       !jobSeeker.favorited_companies?.includes(currentUserCompanyId)
  //     ) {
  //       return false
  //     }

  //     // Only apply other filters if the profile exists
  //     if (!jobSeeker.has_profile) {
  //       return true // Include incomplete profiles unless searching or filtering by favorites
  //     }

  //     // ... (keep rest of your existing filter logic)

  //     return true // Return true if all filters pass
  //   })

  //   setFilteredJobSeekers(filtered)
  // }

  // Handle page changes
  const handleNextPage = () => {
    if (currentPage * JOB_SEEKERS_PER_PAGE < filteredJobSeekers.length) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleGoToPage = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const getAllSubcategories = () => {
    return Object.values(allSubcategories).flat()
  }

  const toggleFilter = (filterName) => {
    setOpenFilters((prev) => ({ ...prev, [filterName]: !prev[filterName] }))
  }

  const toggleDemographicFilter = (filterName) => {
    setOpenDemographicFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }))
    setOpenFilters((prev) => ({ ...prev, demographics: true }))
  }

  const renderFilterOptions = (
    options,
    selected,
    setSelected,
    searchTerm = ''
  ) => {
    const filteredOptions = searchTerm
      ? options.filter((option) =>
          option?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : options

    const selectedOptions = filteredOptions.filter((option) =>
      selected.includes(option)
    )
    const unselectedOptions = filteredOptions.filter(
      (option) => !selected.includes(option)
    )

    return (
      <>
        {selectedOptions.map((option) => (
          <CheckboxLabel key={option}>
            <Checkbox
              type="checkbox"
              checked={true}
              onChange={() => {
                setSelected(selected.filter((item) => item !== option))
              }}
            />
            {option}
            <ClearButton
              onClick={() =>
                setSelected(selected.filter((item) => item !== option))
              }
            >
              <X size={12} />
            </ClearButton>
          </CheckboxLabel>
        ))}
        {unselectedOptions.map((option) => (
          <CheckboxLabel key={option}>
            <Checkbox
              type="checkbox"
              checked={false}
              onChange={() => {
                setSelected([...selected, option])
              }}
            />
            {option}
          </CheckboxLabel>
        ))}
      </>
    )
  }
  const loadJobSeekers = async () => {
    try {
      setLoading(true)

      // First get all users
      const users = await getJobSeekers()

      // Then get available profiles
      const profiles = await getAllJobSeekerProfiles(
        users.map((user) => user.id)
      )

      // Combine data
      const combinedData = users.map((user) => ({
        id: user.id,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        created_at: user.created_at,
        has_profile: !!profiles[user.id],
        // Add profile data if it exists
        ...(profiles[user.id] || {})
      }))

      setJobSeekers(combinedData)
      setFilteredJobSeekers(combinedData)
    } catch (err) {
      console.error('Error loading job seekers:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadJobSeekers()
  }, [])
  const getFilteredSubcategories = () => {
    if (selectedIndustries.length === 0) {
      // If no industries selected, show all subcategories
      return Object.values(allSubcategories)
        .flat()
        .filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
    } else {
      // Show subcategories only for selected industries
      return selectedIndustries.reduce((acc, industry) => {
        const filteredSubs = allSubcategories[industry].filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
        return [...acc, { industry, subcategories: filteredSubs }]
      }, [])
    }
  }
  const filterJobSeekers = () => {
    if (!jobSeekers.length) return

    // Filter job seekers based on search criteria
    const filtered = jobSeekers.filter((jobSeeker) => {
      // Exclude job seekers without profiles if needed
      if (!jobSeeker.has_profile) return false // Set to `false` to exclude them entirely

      // Basic text search across relevant fields
      if (searchTerm) {
        const searchFields = [
          jobSeeker.first_name,
          jobSeeker.last_name,
          jobSeeker.email,
          jobSeeker.current_title,
          jobSeeker.current_employer,
          jobSeeker.location,
          jobSeeker.education
            ?.map((edu) => `${edu.university} ${edu.degree} ${edu.major}`)
            .join(' '),
          jobSeeker.top_strengths?.join(' '),
          jobSeeker.languages?.join(' ')
        ]
          .filter(Boolean)
          .join(' ')
          .toLowerCase()

        if (!searchFields.includes(searchTerm.toLowerCase())) {
          return false
        }
      }

      // Favorited Filter
      if (
        showFavoritedOnly &&
        !jobSeeker.favorites?.some((id) => companyEmployerIds.includes(id))
      ) {
        return false
      }

      // Location Filter
      if (
        selectedLocations.length > 0 &&
        !selectedLocations.includes(jobSeeker.location)
      ) {
        return false
      }

      // Current Title Filter
      if (
        selectedCurrentTitles.length > 0 &&
        !selectedCurrentTitles.includes(jobSeeker.current_title)
      ) {
        return false
      }

      // Current Employer Filter
      if (
        selectedCurrentEmployers.length > 0 &&
        !selectedCurrentEmployers.includes(jobSeeker.current_employer)
      ) {
        return false
      }

      // Work Experience Filter
      if (selectedWorkExperience.length > 0) {
        const experience =
          jobSeeker.years_experience === 'None/Student'
            ? 'Student'
            : jobSeeker.years_experience
        if (!selectedWorkExperience.includes(experience)) {
          return false
        }
      }

      // Current Level Filter
      if (
        selectedCurrentLevel.length > 0 &&
        !selectedCurrentLevel.includes(jobSeeker.current_level)
      ) {
        return false
      }

      // Education Filters
      if (jobSeeker.education) {
        // Highest Degree Filter
        if (
          selectedHighestDegree.length > 0 &&
          !selectedHighestDegree.includes(
            jobSeeker.education[jobSeeker.education.length - 1].degree
          )
        ) {
          return false
        }

        // University Filter
        if (selectedUniversities.length > 0) {
          const hasMatchingUniversity = jobSeeker.education.some((edu) =>
            selectedUniversities.includes(edu.university)
          )
          if (!hasMatchingUniversity) return false
        }

        // Major Filter
        if (selectedMajors.length > 0) {
          const hasMatchingMajor = jobSeeker.education.some((edu) =>
            selectedMajors.includes(edu.major)
          )
          if (!hasMatchingMajor) return false
        }

        // HBCU Filter
        if (selectedHBCU && !jobSeeker.education.some((edu) => edu.is_hbcu)) {
          return false
        }
      }

      // Strengths Filter
      if (selectedStrengths.length > 0 && jobSeeker.top_strengths) {
        const hasAnyStrength = selectedStrengths.some((strength) =>
          jobSeeker.top_strengths.includes(strength)
        )
        if (!hasAnyStrength) return false
      }

      // Willing to Relocate Filter
      if (
        selectedWillingToRelocate !== null &&
        jobSeeker.willing_to_relocate !== selectedWillingToRelocate
      ) {
        return false
      }

      // Startup Experience Filter
      if (
        selectedStartupExperience.length > 0 &&
        !selectedStartupExperience.includes(
          jobSeeker.startup_experience ? 'Yes' : 'No'
        )
      ) {
        return false
      }

      // Languages Filter
      if (selectedLanguages.length > 0 && jobSeeker.languages) {
        const hasAnyLanguage = selectedLanguages.some((lang) =>
          jobSeeker.languages.includes(lang)
        )
        if (!hasAnyLanguage) return false
      }

      // Athlete Status Filter
      if (
        selectedAthleteStatus.length > 0 &&
        !selectedAthleteStatus.includes(jobSeeker.athlete_status)
      ) {
        return false
      }

      // Gender Filter
      if (
        selectedGenders.length > 0 &&
        !selectedGenders.includes(jobSeeker.gender)
      ) {
        return false
      }

      // Race Filter
      if (selectedRaces.length > 0 && !selectedRaces.includes(jobSeeker.race)) {
        return false
      }

      // Veteran Status Filter
      if (
        selectedVeteranStatus.length > 0 &&
        !selectedVeteranStatus.includes(jobSeeker.veteran_status)
      ) {
        return false
      }

      // Industry and Subcategory Filters
      if (selectedIndustries.length > 0 || selectedSubcategories.length > 0) {
        const jobSeekerIndustries = jobSeeker.industries || []
        const jobSeekerSubcategories = jobSeeker.subcategories || []

        const hasMatchingIndustry = selectedIndustries.some((industry) =>
          jobSeekerIndustries.includes(industry)
        )
        const hasMatchingSubcategory = selectedSubcategories.some(
          (subcategory) => jobSeekerSubcategories.includes(subcategory)
        )

        if (
          (selectedIndustries.length > 0 && !hasMatchingIndustry) ||
          (selectedSubcategories.length > 0 && !hasMatchingSubcategory)
        ) {
          return false
        }
      }

      return true
    })

    // Sort to move incomplete profiles to the bottom
    filtered.sort((a, b) => {
      // Move job seekers without profiles to the bottom
      if (!a.has_profile && b.has_profile) return 1
      if (a.has_profile && !b.has_profile) return -1

      // Then apply the regular sort by rating or relevance, for example
      return sortOrder === 'asc' ? a.rating - b.rating : b.rating - a.rating
    })

    setFilteredJobSeekers(filtered)
    setFavoritedCount(
      filtered.filter((js) =>
        js.favorites?.some((id) => companyEmployerIds.includes(id))
      ).length
    )
  }

  // Add useEffect to trigger filtering when any filter changes
  useEffect(() => {
    filterJobSeekers()
  }, [
    searchTerm,
    showFavoritedOnly,
    selectedIndustries,
    selectedSubcategories,
    selectedLocations,
    currentUserCompanyId,
    selectedCurrentEmployers,
    selectedCurrentTitles,
    selectedWorkExperience,
    selectedCurrentLevel,
    selectedHighestDegree,
    selectedMajors,
    selectedUniversities,
    currentUserEmployerId,
    selectedHBCU,
    selectedStrengths,
    selectedWillingToRelocate,
    selectedStartupExperience,
    selectedLanguages,
    selectedGenders,
    selectedRaces,
    selectedAthleteStatus,
    selectedVeteranStatus,
    sortOrder
  ])
  const toggleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'))
    setFilteredJobSeekers((prev) =>
      [...prev].sort((a, b) =>
        sortOrder === 'asc' ? b.rating - a.rating : a.rating - b.rating
      )
    )
  }

  const getMatchColor = (percentage) => {
    if (percentage >= 80) return '#48bb78'
    if (percentage >= 60) return '#ed8936'
    if (percentage >= 40) return '#ecc94b'
    return '#f56565'
  }

  const toggleFavorite = (e, profileId) => {
    e.stopPropagation()
    setFavorites((prev) => {
      const newFavorites = new Set(prev)
      if (newFavorites.has(profileId)) {
        newFavorites.delete(profileId)
      } else {
        newFavorites.add(profileId)
      }
      return newFavorites
    })
  }

  const togglePipeline = (e, profileId) => {
    e.stopPropagation()
    setPipeline((prev) => {
      const newPipeline = new Set(prev)
      if (newPipeline.has(profileId)) {
        newPipeline.delete(profileId)
      } else {
        newPipeline.add(profileId)
      }
      return newPipeline
    })
  }

  const clearAllFilters = () => {
    setSelectedLocations([])
    setSelectedCurrentEmployers([])
    setSelectedCurrentTitles([])
    setSelectedWorkExperience([])
    setSelectedCurrentLevel([])
    setSelectedHighestDegree([])
    setSelectedMajors([])
    setSelectedUniversities([])
    setSelectedHBCU(false)
    setSelectedStrengths([])
    setSelectedWillingToRelocate(null)
    setSelectedGenders([])
    setSelectedRaces([])
    setSelectedStartupExperience([])
    setSelectedLanguages([])
    setSelectedAthleteStatus([])
    setSelectedVeteranStatus([])
    setOpenFilters({})
    setOpenDemographicFilters({})
    setSearchTerm('')
  }

  const getMatchDescription = () => {
    return 'AI matches will be available Monday Oct 28'
  }

  const handleSearch = (e) => {
    const value = e.target.value
    setSearchTerm(value)
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Header>🏟 Browse Job Seekers</Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search job seekers (name, email, location, employer, title, education, skills...)"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Button onClick={filterJobSeekers}>
            <Search size={18} />
            Search
          </Button>
        </SearchBar>
        {/* <FavoriteFilterWrapper>
          <FavoriteLabel>
            <Checkbox
              type="checkbox"
              checked={showFavoritedOnly}
              onChange={() => setShowFavoritedOnly(!showFavoritedOnly)}
            />
            <Heart
              size={18}
              color={showFavoritedOnly ? '#CAAAE1' : '#666'}
              fill={showFavoritedOnly ? '#CAAAE1' : 'none'}
            />
            Show only job seekers who favorited your company
          </FavoriteLabel>
        </FavoriteFilterWrapper> */}

        <ContentGrid>
          <FiltersColumn>
            {/* Your existing sidebar JSX here */}

            <FilterSection>
              <h1>Filters</h1>
              {/* Location Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('location')}>
                  Location
                  {openFilters.location ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.location}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search locations"
                    value={locationSearch}
                    onChange={(e) => setLocationSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(jobSeekers.map((p) => p.location).filter(Boolean))
                    ),
                    selectedLocations,
                    setSelectedLocations,
                    locationSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Title Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentTitle')}>
                  Current Title
                  {openFilters.currentTitle ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentTitle}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current titles"
                    value={currentTitleSearch}
                    onChange={(e) => setCurrentTitleSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers.map((p) => p.current_title).filter(Boolean)
                      )
                    ),
                    selectedCurrentTitles,
                    setSelectedCurrentTitles,
                    currentTitleSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Level Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentLevel')}>
                  Current Level
                  {openFilters.currentLevel ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentLevel}>
                  {renderFilterOptions(
                    [
                      'Internship',
                      'Entry Level',
                      'Manager',
                      'Director',
                      'Head / Lead',
                      'Vice President',
                      'Senior Vice President',
                      'C-Suite Executive'
                    ],
                    selectedCurrentLevel,
                    setSelectedCurrentLevel
                  )}
                </FilterContent>
              </FilterSection>
              {/* Work Experience Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('workExperience')}>
                  Work Experience
                  {openFilters.workExperience ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.workExperience}>
                  {renderFilterOptions(
                    [
                      'None/Student',
                      '1-2 years',
                      '3-5 years',
                      '6-10 years',
                      '11-15 years',
                      '16+ years'
                    ],
                    selectedWorkExperience,
                    setSelectedWorkExperience
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Employer Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentEmployer')}>
                  Employer
                  {openFilters.currentEmployer ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentEmployer}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current employers"
                    value={currentEmployerSearch}
                    onChange={(e) => setCurrentEmployerSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers
                          .map((p) => p.current_employer)
                          .filter(Boolean)
                      )
                    ),
                    selectedCurrentEmployers,
                    setSelectedCurrentEmployers,
                    currentEmployerSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Education Filters */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('universities')}>
                  College/University
                  {openFilters.universities ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.universities}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search universities"
                    value={universitySearch}
                    onChange={(e) => setUniversitySearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers.flatMap(
                          (p) =>
                            p.education
                              ?.map((edu) => edu.university)
                              .filter(Boolean) || []
                        )
                      )
                    ),
                    selectedUniversities,
                    setSelectedUniversities,
                    universitySearch
                  )}
                  <NestedFilterContent>
                    <FilterTitle onClick={() => toggleFilter('hbcu')}>
                      HBCU
                      {openFilters.hbcu ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openFilters.hbcu}>
                      <CheckboxLabel>
                        <Checkbox
                          type="checkbox"
                          checked={selectedHBCU === true}
                          onChange={() => setSelectedHBCU((prev) => !prev)}
                        />
                        Yes
                      </CheckboxLabel>
                    </FilterContent>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
              {/* Highest Degree Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('highestDegree')}>
                  Highest Level Of Education
                  {openFilters.highestDegree ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.highestDegree}>
                  {renderFilterOptions(
                    [
                      'Some high school or less',
                      'High school diploma or equivalent',
                      'Vocational certificate or credential',
                      'Some college',
                      'Associates degree',
                      'Bachelors degree',
                      'Masters degree',
                      'PhD',
                      'MD, OD or related',
                      'DDS, DMD or related',
                      'JD',
                      'Other'
                    ],
                    selectedHighestDegree,
                    setSelectedHighestDegree
                  )}
                </FilterContent>
              </FilterSection>
              {/* Industry Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('industry')}>
                  Industry
                  {openFilters.industry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.industry}>
                  <div className="max-h-64 overflow-y-auto">
                    {industries
                      .filter((industry) =>
                        industry
                          .toLowerCase()
                          .includes(industrySearch.toLowerCase())
                      )
                      .map((industry) => (
                        <CheckboxLabel key={industry}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedIndustries.includes(industry)}
                            onChange={() => {
                              if (selectedIndustries.includes(industry)) {
                                setSelectedIndustries(
                                  selectedIndustries.filter(
                                    (i) => i !== industry
                                  )
                                )
                                // Remove all subcategories of this industry
                                setSelectedSubcategories(
                                  selectedSubcategories.filter(
                                    (sub) =>
                                      !allSubcategories[industry].includes(sub)
                                  )
                                )
                              } else {
                                setSelectedIndustries([
                                  ...selectedIndustries,
                                  industry
                                ])
                              }
                            }}
                          />
                          {industry}
                          {/* <span className="text-sm text-gray-500 ml-2">
                            ({allSubcategories[industry].length})
                          </span> */}
                        </CheckboxLabel>
                      ))}
                  </div>
                  {selectedIndustries.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => {
                          setSelectedIndustries([])
                          setSelectedSubcategories([])
                        }}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedIndustries.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Subcategory Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('subcategory')}>
                  Company Type
                  {openFilters.subcategory ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.subcategory}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search subcategories"
                    value={subcategorySearch}
                    onChange={(e) => setSubcategorySearch(e.target.value)}
                  />
                  <FilterGrid>
                    {selectedIndustries.length === 0
                      ? // Show all subcategories in grid layout
                        getFilteredSubcategories().map((subcategory) => (
                          <FilterCheckboxLabel key={subcategory}>
                            <Checkbox
                              type="checkbox"
                              checked={selectedSubcategories.includes(
                                subcategory
                              )}
                              onChange={() => {
                                if (
                                  selectedSubcategories.includes(subcategory)
                                ) {
                                  setSelectedSubcategories(
                                    selectedSubcategories.filter(
                                      (s) => s !== subcategory
                                    )
                                  )
                                } else {
                                  setSelectedSubcategories([
                                    ...selectedSubcategories,
                                    subcategory
                                  ])
                                }
                              }}
                            />
                            {subcategory}
                          </FilterCheckboxLabel>
                        ))
                      : // Show categorized subcategories in grid layout
                        getFilteredSubcategories().map(
                          ({ industry, subcategories }) => (
                            <React.Fragment key={industry}>
                              {subcategories.length > 0 && (
                                <>
                                  <CategoryHeader>
                                    {industry} ({subcategories.length})
                                  </CategoryHeader>
                                  {subcategories.map((subcategory) => (
                                    <FilterCheckboxLabel key={subcategory}>
                                      <Checkbox
                                        type="checkbox"
                                        checked={selectedSubcategories.includes(
                                          subcategory
                                        )}
                                        onChange={() => {
                                          if (
                                            selectedSubcategories.includes(
                                              subcategory
                                            )
                                          ) {
                                            setSelectedSubcategories(
                                              selectedSubcategories.filter(
                                                (s) => s !== subcategory
                                              )
                                            )
                                          } else {
                                            setSelectedSubcategories([
                                              ...selectedSubcategories,
                                              subcategory
                                            ])
                                          }
                                        }}
                                      />
                                      {subcategory}
                                    </FilterCheckboxLabel>
                                  ))}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                  </FilterGrid>
                  {selectedSubcategories.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => setSelectedSubcategories([])}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedSubcategories.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Strengths Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('strengths')}>
                  Strengths
                  {openFilters.strengths ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.strengths}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search strengths"
                    value={strengthSearch}
                    onChange={(e) => setStrengthSearch(e.target.value)}
                  />
                  <StrengthsGrid>
                    {strengths
                      .filter((strength) =>
                        strength
                          .toLowerCase()
                          .includes(strengthSearch.toLowerCase())
                      )
                      .map((strength) => (
                        <StrengthCheckboxLabel key={strength}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedStrengths.includes(strength)}
                            onChange={() => {
                              if (selectedStrengths.includes(strength)) {
                                setSelectedStrengths(
                                  selectedStrengths.filter(
                                    (s) => s !== strength
                                  )
                                )
                              } else {
                                setSelectedStrengths([
                                  ...selectedStrengths,
                                  strength
                                ])
                              }
                            }}
                          />
                          {strength}
                        </StrengthCheckboxLabel>
                      ))}
                  </StrengthsGrid>
                  {selectedStrengths.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => setSelectedStrengths([])}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedStrengths.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Willing to Relocate Filter
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('willingToRelocate')}>
                  Willing to Relocate
                  {openFilters.willingToRelocate ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.willingToRelocate}>
                  <CheckboxLabel>
                    <Checkbox
                      type="checkbox"
                      checked={selectedWillingToRelocate === true}
                      onChange={() =>
                        setSelectedWillingToRelocate((prev) =>
                          prev === true ? null : true
                        )
                      }
                    />
                    Yes
                  </CheckboxLabel>
                  <CheckboxLabel>
                    <Checkbox
                      type="checkbox"
                      checked={selectedWillingToRelocate === false}
                      onChange={() =>
                        setSelectedWillingToRelocate((prev) =>
                          prev === false ? null : false
                        )
                      }
                    />
                    No
                  </CheckboxLabel>
                </FilterContent>
              </FilterSection> */}
              {/* Startup Experience Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('startupExperience')}>
                  Startup Experience
                  {openFilters.startupExperience ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.startupExperience}>
                  {renderFilterOptions(
                    ['Yes', 'No'],
                    selectedStartupExperience,
                    setSelectedStartupExperience
                  )}
                </FilterContent>
              </FilterSection>
              {/* Languages Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('languages')}>
                  Languages
                  {openFilters.languages ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.languages}>
                  {renderFilterOptions(
                    languageOptions,
                    selectedLanguages,
                    setSelectedLanguages
                  )}
                </FilterContent>
              </FilterSection>
              {/* Athlete Status Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('athleteStatus')}>
                  Athlete Status
                  {openFilters.athleteStatus ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.athleteStatus}>
                  {renderFilterOptions(
                    athleteStatusOptions,
                    selectedAthleteStatus,
                    setSelectedAthleteStatus
                  )}
                </FilterContent>
              </FilterSection>
              {/* Demographics Section */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('demographics')}>
                  Demographics
                  {openFilters.demographics ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.demographics}>
                  {/* Gender Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('gender')}
                    >
                      Gender
                      {openDemographicFilters.gender ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.gender}>
                      {renderFilterOptions(
                        genderOptions,
                        selectedGenders,
                        setSelectedGenders
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Race/Ethnicity Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('race')}
                    >
                      Race/Ethnicity
                      {openDemographicFilters.race ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.race}>
                      {renderFilterOptions(
                        raceOptions,
                        selectedRaces,
                        setSelectedRaces
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Veteran Status Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('veteranStatus')}
                    >
                      Veteran Status
                      {openDemographicFilters.veteranStatus ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent
                      isOpen={openDemographicFilters.veteranStatus}
                    >
                      {renderFilterOptions(
                        veteranStatusOptions,
                        selectedVeteranStatus,
                        setSelectedVeteranStatus
                      )}
                    </FilterContent>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
              {/* Button Group */}
              <ButtonGroup>
                <Button onClick={clearAllFilters}>Clear</Button>
              </ButtonGroup>
            </FilterSection>
          </FiltersColumn>
          <div>
            {/* <SortContainer>
              <SortButton onClick={toggleSort}>
                <ArrowUpDown size={18} />
                Sort by {showMatchPercentage ? 'Match' : 'Relevance'}{' '}
                {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
              </SortButton>
            </SortContainer> */}

            <JobSeekersColumn>
              <JobSeekersHeader>
                All Job Seekers ({filteredJobSeekers.length})
              </JobSeekersHeader>
              {loading ? (
                <p>Loading...</p>
              ) : (
                paginatedJobSeekers.map((jobSeeker) => (
                  <JobSeekerCard
                    key={jobSeeker.id}
                    onClick={() =>
                      navigate(`/jobseeker-profile-view/${jobSeeker.id}`)
                    }
                  >
                    {jobSeeker.profile_picture ? (
                      <ProfileImage
                        src={jobSeeker.profile_picture}
                        alt={`${jobSeeker.first_name} ${jobSeeker.last_name}`}
                        onError={(e) => {
                          e.target.onerror = null
                          e.target.src = '/default-avatar.png' // Optional: provide a fallback image
                        }}
                      />
                    ) : (
                      <ProfileImageFallback size={24} />
                    )}
                    <JobSeekerInfo>
                      {/* Name and Pronouns */}
                      <JobSeekerNameWrapper>
                        <JobSeekerName>
                          {`${jobSeeker.first_name || ''} ${
                            jobSeeker.last_name || ''
                          }`}
                        </JobSeekerName>
                        {jobSeeker.pronouns && (
                          <Pronouns>({jobSeeker.pronouns})</Pronouns>
                        )}
                      </JobSeekerNameWrapper>

                      {/* Title and Company */}
                      <JobSeekerDetails>
                        {jobSeeker.current_title ||
                        jobSeeker.current_employer ? (
                          <>
                            {jobSeeker.current_title}
                            {jobSeeker.current_employer &&
                              ` at ${jobSeeker.current_employer}`}
                          </>
                        ) : (
                          'This Job Seeker has not filled out their profile yet. Check back soon!'
                        )}
                      </JobSeekerDetails>

                      <JobSeekerDetails>
                        {[
                          jobSeeker.years_experience === 'None/Student'
                            ? 'Student'
                            : jobSeeker.years_experience,
                          jobSeeker.location
                        ]
                          .filter(Boolean)
                          .join(' • ')}
                      </JobSeekerDetails>
                      {/* Education */}
                      {jobSeeker.education &&
                        jobSeeker.education.length > 0 && (
                          <JobSeekerDetails>
                            {jobSeeker.education
                              .map(
                                (edu) =>
                                  `${edu.degree || ''} ${
                                    edu.major ? `in ${edu.major}` : ''
                                  } ${
                                    edu.university
                                      ? `from ${edu.university}`
                                      : ''
                                  }`
                              )
                              .join(' • ')}
                          </JobSeekerDetails>
                        )}

                      {/* Strengths/Skills Tags */}
                      <TagList>
                        {jobSeeker.top_strengths?.length > 0 &&
                          jobSeeker.top_strengths
                            .slice(0, 3)
                            .map((strength, index) => (
                              <Tag key={index}>{strength}</Tag>
                            ))}
                      </TagList>
                    </JobSeekerInfo>
                  </JobSeekerCard>
                ))
              )}
            </JobSeekersColumn>

            {/* Pagination Controls */}
            <PaginationControls>
              <PaginationButton
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
              >
                Previous
              </PaginationButton>
              <PageIndicator>
                Page {currentPage} of{' '}
                {Math.ceil(filteredJobSeekers.length / JOB_SEEKERS_PER_PAGE)}
              </PageIndicator>
              <PaginationButton
                disabled={
                  currentPage * JOB_SEEKERS_PER_PAGE >=
                  filteredJobSeekers.length
                }
                onClick={handleNextPage}
              >
                Next
              </PaginationButton>
            </PaginationControls>
          </div>
        </ContentGrid>
      </MainContent>
    </PageWrapper>
  )
}
